import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledEnergyScore from './energyScore/StyledEnergyScore';

const EnergyScore = ({ score, color, type }) => {
    const [types] = useState({
        electricity: 'el',
        water: 'vatten',
        heat: 'värme'
    });

    return (
        <StyledEnergyScore>
            <h2 className="title">
                Andel kunder som givit positiv feedback om {types[type]}
            </h2>
            <h2 className={`score green`}>
                <FontAwesomeIcon icon={['fas', 'thumbs-up']} /> {score[type].positive}
            </h2>
            <h2 className={`score red`}>
                <FontAwesomeIcon icon={['fas', 'thumbs-down']} /> {score[type].negative}
            </h2>
            <span className={`${color} total`}>/{score[type].total}</span>
        </StyledEnergyScore>
    );
};

const mapStateToProps = state => {
    const { feedback: { score } } = state.data.data;
    return { score }
};

export default connect(
    mapStateToProps,
    {}
)(EnergyScore);