import styled from 'styled-components';

const StyledMapComponent = styled.div`
    height: 600px;
    width: 100%;
    max-width: 1000px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

    .leaflet-container {
        height: 100%;
        width: 100%;
    }

    .filter-checkbox-group {
        list-style: none;
        padding: 1px 20px 20px 20px;
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 0 0px 2px rgba(0, 0, 0, 0.2);
        min-width: 150px;
        text-align: center;

        li {
            label {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .legend {
        list-style: none;
        padding: 1px 20px 20px 20px;
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 0 0px 2px rgba(0, 0, 0, 0.2);
        min-width: 50px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .title {
            width: 100%;
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px;

            .color-box {
                width: 1.5em;
                height: 1.5em;
                border-radius: 3px;
                border: 3px solid;
                margin-left: 0.5em;
            }
        }
    }
`;

export default StyledMapComponent;