import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHashtag, faSmile, faMeh, faFrown, faLongArrowAltDown, faLongArrowAltUp, faLongArrowAltRight, faPhone, faEnvelope, faClock, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import StyledApp from './app/StyledApp';
import Header from 'containers/Header';
import Footer from 'containers/Footer';
import Main from 'containers/Main';

library.add(faHashtag, faSmile, faMeh, faFrown, faLongArrowAltDown, faLongArrowAltUp, faLongArrowAltRight, faPhone, faEnvelope, faClock, faThumbsUp, faThumbsDown);

const App = () => (
    <Router>
        <StyledApp>
            <Header />
            <Main />
            <Footer />
        </StyledApp>
    </Router>
);

export default App;