import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledEnergyOverview from './energyOverview/StyledEnergyOverview';
import map from './energyOverview/map.png'
import Mood from 'components/Mood';

const EnergyOverview = ({ userGoals, usage, trend, onTrack }) => {
    const [situation] = useState(getSituation(31));

    function getSituation(days) {
        const usageSum = sumUsage(usage, days);
        if (usageSum < userGoals * days) {
            return 2;
        }
        if (usageSum > userGoals * 1.2 * days) {
            return 0;
        }
        return 1;
    }

    function sumUsage(usage, days) {
        return _.reduce(usage, (prev, type) => {
            return prev + _.reduce(type.slice(-days), (prev, curr) => {
                return prev + curr.y;
            }, 0);
        }, 0);
    }

    return (
        <StyledEnergyOverview>
            <ul className="short-info">
                <li>
                    <Mood mood={['bad', 'neutral', 'good'][situation]} size="5x" />
                    <h2>
                        Fastighetens energiåtgång just nu är <span className={['bad', 'neutral', 'good'][situation]}>
                            {
                                ['hög', 'medelhög', 'låg'][situation]
                            }
                        </span>
                    </h2>
                </li>
                <li>
                    <Mood mood={['bad', 'neutral', 'good'][trend]} size="5x" />
                    <h2>
                        Trenden på fastighetens energiåtgång rör sig <span className={['bad', 'neutral', 'good'][trend]}>
                            {
                                ['uppåt', 'inte', 'neråt'][trend]
                            }
                        </span>
                    </h2>
                </li>
                <li>
                    <Mood mood={['bad', 'neutral', 'good'][onTrack]} size="5x" />
                    <h2>
                        Fastigheten kommer <span className={['bad', 'neutral', 'good'][onTrack]}>
                            {
                                ['antagligen inte', 'kanske', 'antagligen'][onTrack]
                            }
                        </span> att nå sitt energimål
                    </h2>
                </li>
            </ul>
            <div className="map">
                <h2>Välj område</h2>
                <img src={map} alt="map" />
            </div>
        </StyledEnergyOverview>
    )
}

const mapStateToProps = (state) => {
    const { usage, userGoals, trend, onTrack } = state.data.data;

    return { usage, userGoals, trend, onTrack };
}

export default connect(
    mapStateToProps,
    {}
)(EnergyOverview);