import styled from 'styled-components';

const StyledMain = styled.div`
    grid-area: main;
    min-width: 600px;
    padding: 0 50px 0 50px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export default StyledMain;