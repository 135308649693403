import React from 'react';

import StyledMapPage from 'pages/mapPage/StyledMapPage';
import MapComponent from './mapPage/MapComponent';



const MapPage = () => {
    return (
        <StyledMapPage>
            <MapComponent />
        </StyledMapPage>
    )
}

export default MapPage;