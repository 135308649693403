import styled from 'styled-components';

const StyledFeedbackCounter = styled.div`

    .count {
        font-size: 30pt;
        grid-area: count;
        text-align: center;
    }

    .title {
        grid-area: title;
    }
`;

export default StyledFeedbackCounter;