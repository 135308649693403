import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body, html {
        margin: 0;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 10pt;
    }
`

export default GlobalStyle;