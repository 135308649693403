import React from 'react';
import { Animate, AnimateGroup } from 'react-simple-animate';
import _ from 'underscore';
import uniqid from 'uniqid';

const FadeInFromSide = ({ children, className }) => (
    <div className={className}>
        <AnimateGroup play>
            {
                _.map(children, (child, index) => (
                    <Animate
                        startStyle={{ opacity: 0, transform: 'translateX(-10px)' }}
                        durationSeconds={0.3}
                        easeType='cubic-bezier(0.19, 1, 0.22, 1)'
                        sequenceIndex={index}
                        key={uniqid()}
                        overlaySeconds={0.8}
                    >
                        {child}
                    </Animate>
                ))
            }
        </AnimateGroup>
    </div>
);

export default FadeInFromSide;