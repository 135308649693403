import React from 'react';

import StyledDetailComments from './detailComments/StyledDetailComments';

const DetailComments = ({ situation, trend, onTrack, type }) => {
    return (
        <StyledDetailComments className="short-info">
            <li>
                <h2>
                    Fastighetens {type}förbrukning just nu är <span className={['bad', 'neutral', 'good'][situation]}>
                        {
                            ['hög', 'medelhög', 'låg'][situation]
                        }
                    </span>
                </h2>
            </li>
            <li>
                <h2>
                    Trenden på fastighetens {type}förbrukning rör sig <span className={['bad', 'neutral', 'good'][trend]}>
                        {
                            ['uppåt', 'inte', 'neråt'][trend]
                        }
                    </span>
                </h2>
            </li>
            <li>
                <h2>
                    Fastigheten kommer <span className={['bad', 'neutral', 'good'][onTrack]}>
                        {
                            ['antagligen inte', 'kanske', 'antagligen'][onTrack]
                        }
                    </span> att nå sitt mål för {type}förbrukning
                    </h2>
            </li>
        </StyledDetailComments>
    )
};

export default DetailComments;