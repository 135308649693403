import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import StyledHeader from './header/StyledHeader';

const Header = () => {
    return (
        <StyledHeader>
            <ul className="links">
                <li>
                    <Link exact={true} activeClassName="is-active" to="/" ><div><h2>Översikt</h2></div></Link>
                </li>
                <li>
                    <Link activeClassName="is-active" to="/details" ><div><h2>Detaljer</h2></div></Link>
                </li>
                <li>
                    <Link activeClassName="is-active" to="/feedback" ><div><h2>Feedback</h2></div></Link>
                </li>
                <li>
                    <Link activeClassName="is-active" to="/complaints" ><div><h2>Klagomål</h2></div></Link>
                </li>
                <li>
                    <Link activeClassName="is-active" to="/map" ><div><h2>Karta</h2></div></Link>
                </li>
                <li>
                    <Link activeClassName="is-active" to="/heatstats" ><div><h2>Värme</h2></div></Link>
                </li>
            </ul>
        </StyledHeader>
    );
};

export default Header;