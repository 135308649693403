import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledComplaintCard from './complaintCard/StyledComplaintCard';

const ComplaintCard = ({ name, date, email, phone, type, message, status, number }) => {
    const [showMessage, setShowMessage] = useState(false);

    const toggleShowMessage = () => {
        setShowMessage(!showMessage);
    }

    return (
        <StyledComplaintCard onClick={toggleShowMessage} showMessage={showMessage} >
            <div className="title">
                <div className="left">
                    <span className="type">{type}</span>
                    {
                        !showMessage ? <span className="preview" >
                            {message.slice(0, 50)}...
                        </span> :
                            <div className="contact">
                                <span className="name">{name}</span>
                                <div className="details">
                                    <span className="email">
                                        <FontAwesomeIcon icon={['fas', 'envelope']} /> {email}
                                    </span>
                                    <span className="phone">
                                        <FontAwesomeIcon icon={['fas', 'phone']} /> {phone}
                                    </span>
                                </div>
                            </div>
                    }
                </div>
                <div className="right">
                    <div className="upper-right">
                        <span className="number">
                            <FontAwesomeIcon icon={['fas', 'hashtag']} /> {number}
                        </span>
                        <div className={`status ${status}`}></div>
                    </div>
                    <span className="date">
                        <FontAwesomeIcon icon={['fas', 'clock']} /> {date.toLocaleString()}
                    </span>
                </div>
            </div>
            <AnimateHeight duration={300} height={showMessage ? 'auto' : 0} >
                <div className="message">
                    {message}
                </div>
            </AnimateHeight>
        </StyledComplaintCard>
    );
};

export default ComplaintCard;