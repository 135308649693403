import styled from 'styled-components';

const StyledHome = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .top-row {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        .unit-picker {
            max-width: 50%;
        }
    }

    .info {
        text-align: center;
        flex-basis: 40%;

        .saved {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
                
            .value {
                font-size: 20pt;
                padding: 20px;
            }
        }
    }
`;

export default StyledHome;