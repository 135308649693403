import React from 'react';

import StyledEnergyCard from './energyCard/StyledEnergyCard';
import EnergyScore from 'pages/feedback/energyCard/EnergyScore';
import FeedbackCounter from 'pages/feedback/energyCard/FeedbackCounter';

const EnergyCard = ({ type, color }) => {

    return (
        <StyledEnergyCard>
            <EnergyScore type={type} color={color} />
            <FeedbackCounter type={type} color={color} />
        </StyledEnergyCard>
    );
};

export default EnergyCard;