import styled from 'styled-components';

const StyledDetailComments = styled.ul`
    list-style: none;

    .good {
        color: green;
    }

    .bad {
        color: red;
    }

    .neutral {
        color: orange;
    }
`;

export default StyledDetailComments;