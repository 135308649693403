import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledTypeDetails from './typeDetails/StyledTypeDetails';
import BarChart from 'components/BarChart';
import FeedbackCounter from 'pages/feedback/energyCard/FeedbackCounter';
import ContainerLink from 'components/ContainerLink';

const TypeDetails = ({ match: { params: { type } }, homes, days }) => {
    const [values] = useState(cleanData());

    function cleanData() {
        return _.map(homes.slice(-days), home => {
            return {
                name: home.name,
                value: _.reduce(home[type], (prev, curr) => {
                    return prev + curr.y
                }, 0)
            }
        });
    };

    const graph = function () {
        switch (type) {
            case 'electricity':
                return (
                    <div className="bar-chart">
                        <BarChart
                            bars={values}
                            title="Elförbrukning per bostad"
                            yUnit="kWh"
                            type={type}
                            showXLabel={false}
                            unicolor={true}
                        />
                    </div>
                );
            case 'water':
                return (
                    <div className="bar-chart">
                        <BarChart
                            bars={values}
                            title="Vattenförbrukning per bostad"
                            yUnit="Vattenenheter"
                            type={type}
                            showXLabel={false}
                            unicolor={true}
                        />
                    </div>
                );
            case 'heat':
                return (
                    <div className="bar-chart">
                        <BarChart
                            bars={values}
                            title="Värmeförbrukning per bostad"
                            yUnit="Värmeenheter"
                            type={type}
                            showXLabel={false}
                            unicolor={true}
                        />
                    </div>
                );
            default:
                return null
        }
    }(type);

    const colors = {
        electricity: 'purple', water: 'blue', heat: 'orange'
    };

    return (
        <StyledTypeDetails>
            {graph}
            <ContainerLink to={`/complaints/${type}`}>
                <FeedbackCounter type={type} color={colors[type]} />
            </ContainerLink>
        </StyledTypeDetails>
    );
};

const mapStateToProps = (state) => {
    const { homes } = state.data.data;
    return { homes };
};

export default connect(
    mapStateToProps,
    {}
)(TypeDetails);