import styled from 'styled-components';

const StyledTypeDetails = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bar-chart {
        width: 70%;
    }
`;

export default StyledTypeDetails;