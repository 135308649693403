import React from 'react';
import { Route } from 'react-router-dom';

import StyledMain from './main/StyledMain';
import Home from 'pages/Home';
import Details from 'pages/Details';
import TypeDetails from 'pages/TypeDetails';
import Feedback from 'pages/Feedback';
import Complaints from 'pages/Complaints';
import HeatStats from 'pages/HeatStats';
import MapPage from 'pages/MapPage';

const Main = () => {

    return (
        //<CoalCalendar usage={sumUsage(usage, 31)} days={31} dayNumber={14} goal={userGoals * 31} />
        <StyledMain>
            <Route exact path="/" component={Home} />
            <Route exact path="/details" component={Details} />
            <Route path="/details/:type" component={TypeDetails} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/complaints" component={Complaints} />
            <Route path="/complaints/:type" component={Complaints} />
            <Route exact path="/map" component={MapPage} />
            <Route exact path="/heatstats" component={HeatStats} />
        </StyledMain>
    );
};

export default Main;