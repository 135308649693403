import { combineReducers } from 'redux'
import _ from 'underscore';
import uniqid from 'uniqid';

function generateUsageData(points, min = 0, max = 100, step = max / 10) {
    if (max < min) {
        let tmp = max
        max = min
        min = tmp
    }
    let result = []
    let last = Math.random() * (max - min) + min
    for (let i = 0; i < points; i++) {
        let generated = Math.random() * step
        let current = last + generated - step / 2
        current = current < min ? min : current > max ? max : current

        let date = new Date();
        date.setDate(date.getDate() - points + i)
        result.push({
            x: date,
            y: current
        })
        last = current
    }
    return result
}

function generateEstateData(homes, points, min = 0, max = 100, step = max / 10) {
    return _.map(_.range(homes), (index) => {
        let name = '' + index;
        return {
            name,
            electricity: generateUsageData(points, min, max, step),
            water: generateUsageData(points, min, max, step),
            heat: generateUsageData(points, min, max, step)
        }
    });
}

function getRandomDate(from, to) {
    from = from.getTime();
    to = to.getTime();
    return new Date(from + Math.random() * (to - from));
}

// function generateInstructions(days) {
//     return _.map(_.range(days), (i) => {
//         let date = new Date();
//         date.setDate(date.getDate() + i);

//         return {
//             temperature: 17 + Math.round(Math.random() * 6),
//             date
//         }
//     });
// }

// function generateOutcome(instructions) {
//     return _.map(instructions, (instruction) => {
//         const temperature = instruction.temperature + Math.random() * 6 - 3;
//         return {
//             temperature,
//             date: instruction.date
//         }
//     });
// }

// function generateHeatStats(days) {
//     let instructions = generateInstructions(30);
//     let outcome = generateOutcome(instructions);

//     return {
//         instructions, outcome
//     };
// }

function generateComplaints(count, days = 7) {
    return _.map(_.range(count), (index) => {
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - days);
        return {
            name: `Förnamn${index + 1} Efternamn${index + 1}`,
            number: uniqid(),
            date: getRandomDate(fromDate, new Date()),
            email: `${index}.${index}son@mail.com`,
            phone: Math.random().toString().slice(2, 11),
            type: ['electricity', 'water', 'heat'][Math.floor(Math.random() * 2.99)],
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            status: ['done', 'pressing', 'todo'][Math.floor(Math.random() * 2.99)]
        }
    })
}

function generateFeedbackScore(max = 100) {
    let electricityTotal = Math.round(Math.random() * max);
    let waterTotal = Math.round(Math.random() * max);
    let heatTotal = Math.round(Math.random() * max);
    let electricityPositive = Math.round(Math.random() * electricityTotal);
    let waterPositive = Math.round(Math.random() * waterTotal);
    let heatPositive = Math.round(Math.random() * heatTotal);

    let result = {
        electricity: {
            positive: electricityPositive,
            negative: Math.round(Math.random() * (electricityTotal - electricityPositive)),
            total: electricityTotal
        },
        water: {
            positive: waterPositive,
            negative: Math.round(Math.random() * (waterTotal - waterPositive)),
            total: waterTotal
        },
        heat: {
            positive: heatPositive,
            negative: Math.round(Math.random() * (heatTotal - heatPositive)),
            total: heatTotal
        }
    }
    return result;
}

const initialState = {
    usage: {
        electricity: generateUsageData(365),
        water: generateUsageData(365),
        heat: generateUsageData(365)
    },
    userGoals: 111,
    co2: {
        current: Math.random() * 10000 / 12,
        previous: Math.random() * 10000 / 12,
        goal: 7000
    },
    goal: {
        electricity: generateUsageData(365),
        water: generateUsageData(365),
        heat: generateUsageData(365)
    },
    trend: Math.floor(Math.random() * 2.99),
    onTrack: Math.floor(Math.random() * 2.99),
    homes: generateEstateData(20, 365),
    feedback: {
        score: generateFeedbackScore(),
        complaints: generateComplaints(20)
    },
    alerts: [
        "Producenten har rapporterat att elen kommer att vara nere 23/3 - 24/3"
    ],
    estateList: [
        {
            label: "Fastighet 1",
            value: "estate1",
            children: [
                {
                    label: "Bostad 1-1",
                    value: "home1-1"
                },
                {
                    label: "Bostad 1-2",
                    value: "home1-2"
                },
                {
                    label: "Bostad 1-3",
                    value: "home1-3"
                }
            ]
        },
        {
            label: "Fastighet 2",
            value: "estate2",
            children: [
                {
                    label: "Bostad 2-1",
                    value: "home2-1"
                },
                {
                    label: "Bostad 2-2",
                    value: "home2-2"
                },
                {
                    label: "Bostad 2-3",
                    value: "home2-3"
                }
            ]
        },
        {
            label: "Fastighet 3",
            value: "estate3",
            children: [
                {
                    label: "Bostad 3-1",
                    value: "home3-1"
                },
                {
                    label: "Bostad 3-2",
                    value: "home3-2"
                },
                {
                    label: "Bostad 3-3",
                    value: "home3-3"
                }
            ]
        },
    ]
}

const data = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default combineReducers({
    data
})