import React from 'react';

import StyledFooter from './footer/StyledFooter';

const Footer = () => {
    return (
        <StyledFooter>

        </StyledFooter>
    );
};

export default Footer;