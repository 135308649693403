import styled from 'styled-components';

const StyledEnergyScore = styled.div`
    display: flex;
    flex-wrap: wrap;

    .title {
        width: 100%;
    }

    .score {
        font-size: 50pt;
        width: 50%;
    }

    .purple {
        color: purple;
    }

    .blue {
        color: blue;
    }
    
    .orange {
        color: orange;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .total {
        font-size: 30pt;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
`;

export default StyledEnergyScore;