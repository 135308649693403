import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledFeedbackCounter from './feedbackCounter/StyledFeedbackCounter';

const FeedbackCounter = ({ complaints, type, color }) => {

    return (
        <StyledFeedbackCounter>
            <h2 className="title">Antal nya klagomål:</h2>
            <h2 className={`count ${color}`}>{
                _.reduce(complaints, (prev, complaint) => {
                    return complaint.type === type ? prev + 1 : prev;
                }, 0)
            }</h2>
        </StyledFeedbackCounter>
    );
};

const mapStateToProps = state => {
    const { feedback: { complaints } } = state.data.data;
    return { complaints };
};

export default connect(
    mapStateToProps,
    {}
)(FeedbackCounter);