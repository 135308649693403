import styled from 'styled-components';

const StyledAlert = styled.div`
    display: flex;
    width: 100%;
    min-height: 100px;
    box-shadow: 2px 2px 2px 2pt #ccc;
    background-color: yellow;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    h2 {
        margin: 20px 50px 20px 50px;
    }
`;

export default StyledAlert;