import styled from 'styled-components';

const StyledEnergyOverview = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 20px;

    .map {
        box-shadow: 2px 2px 2px 2pt #ccc;
        text-align: center;
    }

    li {
        display: flex;
        align-items: center;
        line-height: 5em;

        h2 {
            margin-left: 50px;
        }
    }

    .short-info {
        list-style: none;
    }

    .good {
        color: green;
    }

    .bad {
        color: red;
    }

    .neutral {
        color: orange;
    }
`;

export default StyledEnergyOverview;