import React, { useState, useEffect } from 'react';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import _ from 'underscore';
import uniqid from 'uniqid';
import Control from 'react-leaflet-control';

import 'leaflet/dist/leaflet.css';

import StyledMapComponent from './mapComponent/StyledMapComponent';

const MapComponent = () => {
    const [position] = useState([58.588678, 16.190677]);
    const [checkboxes, setCheckboxes] = useState([
        { name: "temperature", key: uniqid(), label: "Temperatur", checked: false },
        { name: "energyUsage", key: uniqid(), label: "Energiförbrukning", checked: false },
        { name: "complaints", key: uniqid(), label: "Klagomål", checked: false }
    ]);
    const [facets, setFacets] = useState([]);

    const handleClick = (e) => {
    }

    const handleCheck = (e) => {
        const item = e.target.name;
        const checked = e.target.checked;
        let newCheckboxes = _.map(checkboxes, checkbox => {
            if (checkbox.name === item) {
                return { ...checkbox, checked }
            }
            return checkbox;
        });
        setCheckboxes(newCheckboxes);
    }

    useEffect(() => {
        setFacets(_.map(_.filter(checkboxes, (checkbox) => checkbox.checked), (c) => {
            return c.name;
        }));
    }, [checkboxes])

    return (
        <StyledMapComponent>
            <Map center={position} zoom={15} onClick={handleClick} >
                <TileLayer
                    url='https://{s}.tiles.mapbox.com/v3/osmbuildings.kbpalbpk/{z}/{x}/{y}.png'
                    attribution='© Map <a href="https://mapbox.com">Mapbox</a>'
                />
                {
                    renderBuildings(facets)
                }
                <Control position="topleft">
                    <ul className="filter-checkbox-group">
                        <h2 className="title">Visa status för</h2>
                        {
                            _.map(checkboxes, (checkbox) => (
                                <li key={checkbox.key}>
                                    <label>
                                        {checkbox.label}
                                        <input type="checkbox" name={checkbox.name} checked={checkbox.checked} onChange={handleCheck} />
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                </Control>
                <Control position="topright">
                    <ul className="legend">
                        <h2 className="title">Legend</h2>
                        <li className="good">
                            <span className="color-name">Bra</span>
                            <span className="color-box" style={{ backgroundColor: "hsl(" + hues[2] + ", 100%, 90%)", borderColor: "hsl(" + hues[2] + ", 100%, 50%)" }}></span>
                        </li>
                        <li className="ok">
                            <span className="color-name">Ok</span>
                            <span className="color-box" style={{ backgroundColor: "hsl(" + hues[1] + ", 100%, 90%)", borderColor: "hsl(" + hues[1] + ", 100%, 50%)" }}></span>
                        </li>
                        <li className="bad">
                            <span className="color-name">Dåligt</span>
                            <span className="color-box" style={{ backgroundColor: "hsl(" + hues[0] + ", 100%, 90%)", borderColor: "hsl(" + hues[0] + ", 100%, 50%)" }}></span>
                        </li>
                    </ul>
                </Control>
            </Map>

        </StyledMapComponent>
    )
}

const buildings = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.19050294160843,
                            58.5892898714643
                        ],
                        [
                            16.190626323223114,
                            58.58902986395185
                        ],
                        [
                            16.19210958480835,
                            58.58922696659816
                        ],
                        [
                            16.191959381103512,
                            58.58949955353468
                        ],
                        [
                            16.191776990890503,
                            58.58946181085476
                        ],
                        [
                            16.191650927066803,
                            58.589299656655534
                        ],
                        [
                            16.190814077854156,
                            58.589187825735465
                        ],
                        [
                            16.190752387046814,
                            58.589323420680024
                        ],
                        [
                            16.19050294160843,
                            58.5892898714643
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.19187355041504,
                            58.58980009564418
                        ],
                        [
                            16.190368831157684,
                            58.58959460899263
                        ],
                        [
                            16.190419793128967,
                            58.589489768399346
                        ],
                        [
                            16.191197633743286,
                            58.58959600686509
                        ],
                        [
                            16.1912003159523,
                            58.58957923239194
                        ],
                        [
                            16.191186904907227,
                            58.58956106003698
                        ],
                        [
                            16.191251277923584,
                            58.589467402365486
                        ],
                        [
                            16.191229820251465,
                            58.58945202570887
                        ],
                        [
                            16.191157400608063,
                            58.589438046924286
                        ],
                        [
                            16.19113326072693,
                            58.5894492299524
                        ],
                        [
                            16.1910742521286,
                            58.58954428554707
                        ],
                        [
                            16.19097769260406,
                            58.58953310254933
                        ],
                        [
                            16.19098573923111,
                            58.58950514503937
                        ],
                        [
                            16.190867722034454,
                            58.589491166276005
                        ],
                        [
                            16.190945506095886,
                            58.58936395927274
                        ],
                        [
                            16.19151681661606,
                            58.589438046924286
                        ],
                        [
                            16.19148999452591,
                            58.58948138113831
                        ],
                        [
                            16.191455125808716,
                            58.58947858538421
                        ],
                        [
                            16.191439032554626,
                            58.589467402365486
                        ],
                        [
                            16.191366612911224,
                            58.589618372816766
                        ],
                        [
                            16.191508769989014,
                            58.589639340883494
                        ],
                        [
                            16.19155704975128,
                            58.58962536217374
                        ],
                        [
                            16.19155704975128,
                            58.58960159835436
                        ],
                        [
                            16.191653609275818,
                            58.58962256643114
                        ],
                        [
                            16.191640198230743,
                            58.58966729828624
                        ],
                        [
                            16.191739439964294,
                            58.58967848124107
                        ],
                        [
                            16.191774308681488,
                            58.58961977068829
                        ],
                        [
                            16.191760897636414,
                            58.58960998558657
                        ],
                        [
                            16.191766262054443,
                            58.58959041537495
                        ],
                        [
                            16.191801130771637,
                            58.58958202813803
                        ],
                        [
                            16.191795766353607,
                            58.58955826428921
                        ],
                        [
                            16.19198352098465,
                            58.58957923239194
                        ],
                        [
                            16.19187355041504,
                            58.58980009564418
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.194542348384857,
                            58.59216380428085
                        ],
                        [
                            16.194657683372498,
                            58.59192198926388
                        ],
                        [
                            16.196124851703644,
                            58.59212187116071
                        ],
                        [
                            16.195840537548065,
                            58.5926656000524
                        ],
                        [
                            16.194370687007904,
                            58.5924685167776
                        ],
                        [
                            16.194443106651306,
                            58.592346911649905
                        ],
                        [
                            16.195650100708008,
                            58.59252163152833
                        ],
                        [
                            16.19581639766693,
                            58.5922211127971
                        ],
                        [
                            16.194821298122406,
                            58.59207714244383
                        ],
                        [
                            16.194748878479004,
                            58.592194555203676
                        ],
                        [
                            16.194542348384857,
                            58.59216380428085
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.193855702877045,
                            58.58870135707387
                        ],
                        [
                            16.194099783897396,
                            58.58874049848075
                        ],
                        [
                            16.193973720073696,
                            58.58894738804691
                        ],
                        [
                            16.193729639053345,
                            58.58890684897154
                        ],
                        [
                            16.193855702877045,
                            58.58870135707387
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.193021535873413,
                            58.588487476470036
                        ],
                        [
                            16.19276136159897,
                            58.58900889551987
                        ],
                        [
                            16.19385838508606,
                            58.58915847005972
                        ],
                        [
                            16.193796694278717,
                            58.58927868838528
                        ],
                        [
                            16.193675994873047,
                            58.589257720102395
                        ],
                        [
                            16.193662583827972,
                            58.58927030107363
                        ],
                        [
                            16.193531155586243,
                            58.58925352644433
                        ],
                        [
                            16.193536520004272,
                            58.58924094546707
                        ],
                        [
                            16.193300485610962,
                            58.58920599828429
                        ],
                        [
                            16.193295121192932,
                            58.589221375049014
                        ],
                        [
                            16.193171739578247,
                            58.58920460039626
                        ],
                        [
                            16.193177103996277,
                            58.589185029957875
                        ],
                        [
                            16.19295984506607,
                            58.589155674279795
                        ],
                        [
                            16.19295448064804,
                            58.58917105106663
                        ],
                        [
                            16.19281232357025,
                            58.58915287849963
                        ],
                        [
                            16.192815005779266,
                            58.58914169537679
                        ],
                        [
                            16.192670166492462,
                            58.58912072701185
                        ],
                        [
                            16.192699670791622,
                            58.589066209204155
                        ],
                        [
                            16.1925145983696,
                            58.58904244500499
                        ],
                        [
                            16.192796230316162,
                            58.58845951812453
                        ],
                        [
                            16.193021535873413,
                            58.588487476470036
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.188142597675323,
                            58.59297310365361
                        ],
                        [
                            16.18796020746231,
                            58.59295772853776
                        ],
                        [
                            16.18799775838852,
                            58.59287526189188
                        ],
                        [
                            16.187638342380524,
                            58.59283472736757
                        ],
                        [
                            16.187654435634613,
                            58.59279139730689
                        ],
                        [
                            16.18746131658554,
                            58.59277741985651
                        ],
                        [
                            16.187512278556824,
                            58.5926614068028
                        ],
                        [
                            16.187670528888702,
                            58.59267398655013
                        ],
                        [
                            16.18769198656082,
                            58.5926334517926
                        ],
                        [
                            16.1882284283638,
                            58.59268936179068
                        ],
                        [
                            16.188287436962128,
                            58.592762044654634
                        ],
                        [
                            16.188257932662964,
                            58.592824943165006
                        ],
                        [
                            16.188158690929413,
                            58.592894830266125
                        ],
                        [
                            16.188142597675323,
                            58.59297310365361
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.188657581806183,
                            58.59300804707364
                        ],
                        [
                            16.18868976831436,
                            58.59294095567637
                        ],
                        [
                            16.188767552375793,
                            58.592947944369605
                        ],
                        [
                            16.188735365867615,
                            58.59301783122498
                        ],
                        [
                            16.188657581806183,
                            58.59300804707364
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.18875682353973,
                            58.593072342875274
                        ],
                        [
                            16.188794374465942,
                            58.59299826291955
                        ],
                        [
                            16.189172565937042,
                            58.59304299045876
                        ],
                        [
                            16.189135015010834,
                            58.59311707031975
                        ],
                        [
                            16.18875682353973,
                            58.593072342875274
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                temperature: Math.random(),
                energyUsage: Math.random(),
                complaints: Math.random()
            },
            "score": Math.random(),
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            16.195896863937378,
                            58.59110847213914
                        ],
                        [
                            16.195121705532074,
                            58.59100363608218
                        ],
                        [
                            16.195435523986816,
                            58.59040396779868
                        ],
                        [
                            16.19621604681015,
                            58.59051160132437
                        ],
                        [
                            16.196143627166748,
                            58.590637406324305
                        ],
                        [
                            16.195631325244904,
                            58.59056332121244
                        ],
                        [
                            16.195451617240906,
                            58.59091277791204
                        ],
                        [
                            16.195961236953735,
                            58.59098686228392
                        ],
                        [
                            16.195896863937378,
                            58.59110847213914
                        ]
                    ]
                ]
            }
        }
    ]
}

const hues = [0, 60, 120];

function renderBuildings(facets) {
    return _.map(buildings.features, (building) => {
        let score = facets.length !== 0 ? Math.max.apply(null, _.map(facets, (curr) => building.properties[curr])) : NaN
        return (
            <GeoJSON
                key={uniqid()}
                data={building}
                style={{ color: heatMapColorforValue(score) }}
            />
        );
    });
}

function getHue(h) {
    return hues.reduce(function (prev, curr) {
        return (Math.abs(curr - h) < Math.abs(prev - h) ? curr : prev);
    });
}

function heatMapColorforValue(value) {
    var h = (1.0 - value) * 150
    return !isNaN(value) ? "hsl(" + getHue(h) + ", 100%, 50%)" : "hsl(0, 0%, 50%)";
}



export default MapComponent;