import React from 'react';
import { Link } from 'react-router-dom';

import StyledContainerLink from './containerLink/StyledContainerLink';

const ContainerLink = ({ children, to, className }) => {
    return (
        <StyledContainerLink className={className}>
            {children}
            <Link to={to} className="absolute-fill" />
        </StyledContainerLink>
    );
};

export default ContainerLink;