import styled from 'styled-components';

const StyledHeader = styled.div`
    grid-area: header;
    background-color: rgba(51, 187, 187, 0.8);
    font-size: 12pt;

    ul {
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;

        li {
            color: white;

            @media screen and (pointer: fine) {
                :hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }

            a {
                height: 100%;
                color: inherit;
                font-weight: bold;
                text-decoration: none;
                box-shadow: 1px 1px 2px grey;
                font-family: Verdana, Geneva, Tahoma, sans-serif;

                div {
                    height: 100%;

                    h2 {
                        margin: 0;
                        padding: 20px;
                    }
                }

                &.is-active {
                    div {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
`;

export default StyledHeader;