import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import uniqid from 'uniqid';

import StyledComplaints from './complaints/StyledComplaints';
import ComplaintCard from 'pages/complaints/ComplaintCard';
import FadeInFromSide from 'components/FadeInFromSide';

const typeString = {
    electricity: 'Elektricitet',
    water: 'Vatten',
    heat: 'Värme'
};

const searchSynonyms = {
    akut: ['pressing'],
    ohanterat: ['todo'],
    hanterat: ['done'],
    ohanterad: ['todo'],
    hanterad: ['done'],
    'att göra': ['todo'],
    'färdig': ['done']
};

const Complaints = ({ complaints, match: { params: { type } } }) => {

    const [initialList] = useState(
        _.map(complaints, (complaint) => {
            if (type) {
                return type === complaint.type ?
                    <ComplaintCard {...complaint} type={typeString[complaint.type]} key={uniqid()} /> : null;
            }
            else {
                return <ComplaintCard {...complaint} type={typeString[complaint.type]} key={uniqid()} />;
            }
        })
    );

    const [currentList, setCurrentList] = useState(initialList);

    const filterList = event => {
        setCurrentList(
            _.filter(initialList, item => {
                return item ?
                    _.any(item.props, part => {
                        let stdSearch = ('' + part).toLowerCase().search(
                            event.target.value.toLowerCase()
                        ) !== -1;
                        let term = searchSynonyms[event.target.value.toLowerCase()];
                        let synonymSearch = term && _.any(term, synonym =>
                            ('' + part).toLowerCase().search(synonym) !== -1);

                        return stdSearch || synonymSearch;
                    }) : null;
            })
        )
    };

    return (
        <StyledComplaints>
            <FadeInFromSide className="search" >
                <input type="text" placeholder="Sök" onChange={filterList} />
            </FadeInFromSide>
            <FadeInFromSide>
                {
                    currentList
                }
            </FadeInFromSide>
        </StyledComplaints>
    );
};

const mapStateToProps = state => {
    const { feedback: { complaints } } = state.data.data;
    return { complaints };
}

export default connect(
    mapStateToProps,
    {}
)(Complaints);
