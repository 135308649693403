import styled from 'styled-components';

const StyledContainerLink = styled.div`
    position: relative;

    @media screen and (pointer: fine) {
        & > * {
            transition: background-color 0.2s;
            border-radius: 10px;

            :hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    .absolute-fill {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;

export default StyledContainerLink;