import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledDetails from './details/StyledDetails';
import BarChart from 'components/BarChart';
import GroupFadeInFromSide from 'components/GroupFadeInFromSide';
import ContainerLink from 'components/ContainerLink';
import DetailComments from './details/DetailComments';

const Details = ({ userGoals, homes }) => {
    const [totalElectricity] = useState(getTotalUsage('electricity'));
    const [totalWater] = useState(getTotalUsage('water'));
    const [totalHeat] = useState(getTotalUsage('heat'));
    const [goalElectricity] = useState(userGoals * homes.length * 30 / 3);
    const [goalWater] = useState(userGoals * homes.length * 30 / 2);
    const [goalHeat] = useState(userGoals * homes.length * 30 / 2.5);
    const [electricityComments] = useState({
        situation: Math.floor(Math.random() * 2.99),
        onTrack: Math.floor(Math.random() * 2.99),
        trend: Math.floor(Math.random() * 2.99)
    });
    const [waterComments] = useState({
        situation: Math.floor(Math.random() * 2.99),
        onTrack: Math.floor(Math.random() * 2.99),
        trend: Math.floor(Math.random() * 2.99)
    });
    const [heatComments] = useState({
        situation: Math.floor(Math.random() * 2.99),
        onTrack: Math.floor(Math.random() * 2.99),
        trend: Math.floor(Math.random() * 2.99)
    });

    function getTotalUsage(type, days = 30) {
        return _.reduce(homes, (prev, home) => {
            return prev + _.reduce(home[type].slice(-days), (prev, dayUsage) => {
                return prev + dayUsage.y;
            }, 0)
        }, 0);
    }

    return (
        <StyledDetails>
            <GroupFadeInFromSide className="chart-group">
                <ContainerLink to="/details/electricity" className="bar-chart">
                    <BarChart
                        bars={[
                            { value: totalElectricity, color: totalElectricity > goalElectricity ? 'red' : 'green', name: 'Din fastighet' },
                            { value: goalElectricity, color: 'purple', name: 'Ditt mål' }
                        ]}
                        title="Elförbrukning"
                    />

                </ContainerLink>
                <DetailComments {...electricityComments} type='el' />

                <ContainerLink to="/details/water" className="bar-chart">
                    <BarChart
                        bars={[
                            { value: totalWater, color: totalWater > goalWater ? 'red' : 'green', name: 'Din fastighet' },
                            { value: goalWater, color: 'blue', name: 'Ditt mål' }
                        ]}
                        title="Vattenförbrukning"
                    />

                </ContainerLink>
                <DetailComments {...waterComments} type='vatten' />

                <ContainerLink to="/details/heat" className="bar-chart">
                    <BarChart
                        bars={[
                            { value: totalHeat, color: totalHeat > goalElectricity ? 'red' : 'green', name: 'Din fastighet' },
                            { value: goalHeat, color: 'orange', name: 'Ditt mål' }
                        ]}
                        title="Värmeförbrukning"
                    />

                </ContainerLink>
                <DetailComments {...heatComments} type='värme' />
            </GroupFadeInFromSide>
        </StyledDetails>
    );
};

const mapStateToProps = (state) => {
    const { userGoals, homes } = state.data.data

    return { userGoals, homes };
}

export default connect(
    mapStateToProps,
    {}
)(Details);