import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import {
    VictoryChart,
    VictoryTooltip,
    VictoryLine,
    VictoryZoomContainer,
    VictoryLegend
} from 'victory';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import 'react-dropdown-tree-select/dist/styles.css';

import StyledHeatStats from 'pages/heatStats/StyledHeatStats';

function generateInstructions(days) {
    return _.map(_.range(days), (i) => {
        let date = new Date();
        date.setDate(date.getDate() + i);

        return {
            temperature: 17 + Math.round(Math.random() * 6),
            date
        }
    });
}

function generateOutcome(instructions) {
    return _.map(instructions, (instruction) => {
        const temperature = instruction.temperature + Math.random() * 6 - 3;
        return {
            temperature,
            date: instruction.date
        }
    });
}

function generateHeatStats(days) {
    let instructions = generateInstructions(30);
    let outcome = generateOutcome(instructions);

    return {
        instructions, outcome
    };
}

const findAndReplace = (array, id, object, depth = 0) => {
    let numberId = _.map(id.split('-'), i => Number(i));
    return _.map(array, (element, index) => {
        if (numberId[depth] === index) {
            if (depth < numberId.length - 1) {
                return { ...element, children: findAndReplace(element.children, id, object, depth + 1) };
            }
            if (depth === numberId.length - 1) {
                return { ...element, ...object };
            }
        }
        return element;
    })
}

const HeatStats = ({ estateList: initialEstateList }) => {
    const [instructions, setInstructions] = useState(generateInstructions(30));
    const [outcome, setOutcome] = useState(generateOutcome(instructions));
    const [savedMoney, setSavedMoney] = useState((Math.random() * 200));
    const [savedCo2, setSavedCo2] = useState((Math.random() * 40));
    const [savedEnergy, setSavedEnergy] = useState((Math.random() * 40));
    const [currentEstateList, setCurrentEstateList] = useState([...initialEstateList]);

    const handleChangeChoice = (currentNode, selectedNodes) => {
        let heatStats = generateHeatStats(30);
        setInstructions(heatStats.instructions);
        setOutcome(heatStats.outcome);
        setSavedCo2((Math.random() * 40));
        setSavedEnergy((Math.random() * 40));
        setSavedMoney((Math.random() * 200));
        setCurrentEstateList(findAndReplace(currentEstateList, currentNode._id, { checked: currentNode.checked }));
    }

    const getData = line => {
        return _.map(line, point => {
            return { x: point.date, y: point.temperature }
        });
    };

    return (
        <StyledHeatStats>
            <div className="top-row">
                <h1 className="main-title">
                    Värmestatus för ditt val
                </h1>
                <div className="unit-picker">
                    <DropdownTreeSelect
                        data={currentEstateList}
                        onChange={handleChangeChoice}
                        placeholderText="Välj objekt..."
                        noMatchesText="Inga objekt hittades"
                    />
                </div>
            </div>
            <div className="heat-status-graph">
                <VictoryChart
                    containerComponent={<VictoryZoomContainer />}
                    scale={{ x: "time" }}
                >
                    <VictoryLegend x={100}
                        title=""
                        centerTitle
                        orientation="horizontal"
                        gutter={20}
                        style={{
                            border: { stroke: "black" },
                            title: { fontSize: 20 }
                        }}
                        data={[
                            { name: 'Planerad värme', symbol: { fill: "#aaa" } },
                            { name: 'Faktisk värme', symbol: { fill: "#cc00cc" } }
                        ]}
                    />

                    <VictoryLine
                        interpolation="natural"
                        labels={(d) => `${d.x.toLocaleString('sv-SE')}: ${d.y.toFixed(2)} C\xB0`}
                        labelComponent={<VictoryTooltip />}
                        data={getData(instructions)}
                        style={{ data: { stroke: '#aaa', strokeDasharray: '5,5' } }}
                    />

                    <VictoryLine
                        interpolation="natural"
                        labels={(d) => `${d.x.toLocaleString('sv-SE')}: ${d.y.toFixed(2)} C\xB0`}
                        labelComponent={<VictoryTooltip />}
                        data={getData(outcome)}
                        style={{ data: { stroke: '#c0c' } }}
                    />
                </VictoryChart>
            </div>
            <div className="info">
                <h2>Senaste månaden</h2>
                <div className="saved">
                    <h3 className="title">Sparad energi</h3>
                    <span className="value">{savedEnergy.toFixed(2)}%</span>
                </div>
                <div className="saved">
                    <h3 className="title">Sparat koldioxid</h3>
                    <span className="value">{savedCo2.toFixed(2)}%</span>
                </div>
                <div className="saved">
                    <h3 className="title">Sparade pengar</h3>
                    <span className="value">{savedMoney.toFixed(2)} kr</span>
                </div>
            </div>

        </StyledHeatStats>
    );
};

const mapStateToProps = state => {
    const { estateList } = state.data.data;
    return { estateList };
}

export default connect(
    mapStateToProps,
    {}
)(HeatStats);