import styled from 'styled-components';

const StyledDetails = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .chart-group {
        width: 100%;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > * {
            width: 50%;
        }
    }
`;

export default StyledDetails;