import styled from 'styled-components';

const StyledContainerLink = styled.div`
    border-radius: 10px;
    padding: 20px 40px 20px 40px;
    box-shadow: 2px 2px 2px 1px #ccc;
    cursor: pointer;
    margin-bottom: 5px;

    @media screen {
        :hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        transition: background-color 0.3s ease-out;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .left {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;

            & > * {
                width: 100%;
            }

            .type {
                font-size: 20pt;
                font-style: italic;
            }

            .preview {
                margin-top: 20px;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            flex-wrap: wrap;
            flex-basis: 30%;

            & > * {
                width: 100%;
            }

            .date {
            }

            .upper-right {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin-bottom: 20px;

                .number {
                    font-size: 12pt;
                    font-style: italic;
                    color: grey;
                }

                .status {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;

                    &.done {
                        background-color: green;
                    }
                    
                    &.todo {
                        background-color: orange;
                    }
                    
                    &.pressing {
                        background-color: red;
                    }
                }
            }
        }
    }

    .contact {
        display: flex;
        flex-direction: column;
        color: grey;
        margin-top: 10px;

        .name {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .phone {
            margin-right: auto;
            margin-left: 20px;
        }
    }

    .message {
        margin-top: 20px;
    }
`;

export default StyledContainerLink;