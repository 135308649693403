import styled from 'styled-components';

const StyledFeedback = styled.div`
    width: 100%;

    .slide {
        background: transparent !important;
    }
`;

export default StyledFeedback;