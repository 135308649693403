import React from 'react';

import StyledHome from './home/StyledHome';
import EnergyOverview from 'pages/home/EnergyOverview';
import FadeInFromSide from 'components/FadeInFromSide';
import Alert from 'pages/home/Alert';

const Home = () => {

    return (
        <StyledHome>
            <FadeInFromSide>
                <Alert />
                <EnergyOverview />
            </FadeInFromSide>
        </StyledHome>
    );
};

export default Home;