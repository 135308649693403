import styled from 'styled-components';

const StyledComplaints = styled.div`
    width: 100%;


    .search {
        text-align: right;

        input {
            font-size: 20pt;
            border-radius: 10px;
            padding: 3px 10px 3px 10px;
            margin-bottom: 20px;

            :focus {
                outline-width: 0;
                box-shadow: 0 0 2px 2pt lightblue;
            }
        }
    }
`;

export default StyledComplaints;