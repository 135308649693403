import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import StyledFeedback from './feedback/StyledFeedback';
import ContainerLink from 'components/ContainerLink';
import EnergyCard from 'pages/feedback/EnergyCard';

const Feedback = () => {

    return (
        <StyledFeedback>
            <Carousel showThumbs={false} infiniteLoop autoPlay swipeable showStatus={false} emulateTouch interval={5000} >
                <ContainerLink to="/complaints/electricity">
                    <EnergyCard type="electricity" color="purple" />
                </ContainerLink>

                <ContainerLink to="/complaints/water">
                    <EnergyCard type="water" color="blue" />
                </ContainerLink>

                <ContainerLink to="/complaints/heat">
                    <EnergyCard type="heat" color="orange" />
                </ContainerLink>
            </Carousel>
        </StyledFeedback>
    );
};

export default Feedback;