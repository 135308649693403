import styled from 'styled-components';

const StyledApp = styled.div`
    display: grid;
    grid-template-areas:
    '. header .'
    '. main .'
    '. footer .';
    grid-template-columns: 1fr 8fr 1fr;
    grid-gap: 50px;
`;

export default StyledApp;