import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import uniqid from 'uniqid';

import StyledAlert from './alert/StyledAlert';

const Alert = ({ alerts }) => {

    return (
        <StyledAlert>
            {
                _.map(alerts, alert => {
                    return (
                        <h2 key={uniqid()} >
                            {alert}
                        </h2>
                    );
                })
            }
        </StyledAlert>
    );
};

const mapStateToProps = state => {
    const { alerts } = state.data.data;
    return { alerts }
};

export default connect(
    mapStateToProps,
    {}
)(Alert);