import React, { useState } from 'react';
import {
    VictoryChart,
    VictoryBar,
    VictoryTooltip,
    VictoryLegend,
    VictoryAxis
} from 'victory';


const BarChart = ({ bars, title, yUnit, type, showXLabel = true, showY = true, unicolor }) => {
    const [color] = useState({
        electricity: 'purple',
        water: 'blue',
        heat: 'orange'
    })

    return (
        <VictoryChart
            domainPadding={showXLabel ? 100 : 20}
        >
            {
                showXLabel ?
                    <VictoryAxis /> :
                    <VictoryAxis
                        tickFormat={d => ''}
                    />
            }
            {
                showY &&
                <VictoryAxis
                    dependentAxis
                />
            }
            <VictoryLegend x={125} y={20} title={title} centerTitle data={[]}
                style={{ title: { fontSize: 20 } }}
            />
            <VictoryBar
                alignment="middle"
                labels={(d) => `${d.name}: ${d.value.toFixed(2)} ${yUnit}`}
                labelComponent={<VictoryTooltip />}
                data={bars}
                style={{ data: { fill: d => unicolor ? color[type] : d.color } }}
                x="name"
                y={d => d.value}
            />
        </VictoryChart>
    );
};

export default BarChart;